import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/Hero'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Hero
      title={'Crumbs!'}
      body={`Well, this is awkward. Looks like this page doesn't exist.`}
    />
  </Layout>
)

export default NotFoundPage
